// Borders

$border: 1px solid !default;

// Overrides
body {
	.side-bar {
		.site-header {
			border-bottom: 0 !important;
		}
	}
	
	.main {
		.main-header {
			border-bottom: 0 !important;
		}
	}
}
